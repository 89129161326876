import request from '@/utils/request'

// 查询协议
export function findCardAgreements(params) {
  return request({
    url: `/agreements/card`,
    method: 'get',
    params: params
  })
}

// 添加协议
export function CreateAgreements(data) {
  return request({
    url: `/agreements`,
    method: 'post',
    data
  })
}

// 更新协议在用
export function UpdateAgreementToUsing(agreementId, data) {
  return request({
    url: `/agreements/${agreementId}/using`,
    method: 'patch',
    data
  })
}

// 删除物联网卡协议
export function DeleteCardAgreement(agreementId) {
  return request({
    url: `/agreements/card/${agreementId}`,
    method: 'delete'
  })
}

// 查询平台服务协议
export function findPlatformServiceAgreements(params) {
  return request({
    url: `/agreements/platform_service`,
    method: 'get',
    params: params
  })
}

// 删除平台服务协议
export function deletePlatformServiceAgreement(agreementId) {
  return request({
    url: `/agreements/platform_service/${agreementId}`,
    method: 'delete'
  })
}
