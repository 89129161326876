<template>
  <div>
    <search-agreement @submit="submitSearch" />

    <div class="simiot-table-operation-buttons">
      <a-space>
        <a-button
          type="primary"
          @click="showNewModal"
          v-if="isShowMenu('agreements/upload')"
        >
          上传协议
        </a-button>
      </a-space>
    </div>

    <a-table
      size="middle"
      :scroll="{ x: 'max-content' }"
      :columns="columns"
      :data-source="data"
      :loading="loading"
      :pagination="false"
      row-key="id"
    >
      <div slot="name" slot-scope="text, record">
        <a :href="record.resource_url" target="_blank">{{ record.name }}</a>
      </div>

      <div slot="is_using" slot-scope="text, record">
        <span>{{ record.is_using ? '在用' : '停用' }}</span>
      </div>

      <span slot="actions" slot-scope="text, record">
        <a-space>
          <a
            @click="updateAgreementToUsing(record.id, record.contracting_party_id)"
            v-if="isShowMenu('agreements/using') && !record.is_using"
          >设为在用</a>

          <a
            v-if="isShowMenu('agreements/download')"
            :href="record.download_url"
            download
          >下载</a>

          <a
            class="delete"
            @click="deletePlatformServiceAgreement(record.id)"
            v-if="!record.is_using && record.agent_count==0 && isShowMenu('agreements/delete')"
          >删除</a>
        </a-space>
      </span>
    </a-table>

    <pagination
      v-show="pagination.total_count > 0"
      :total-count="pagination.total_count"
      :page.sync="query.page"
      :per-page.sync="query.per_page"
      @change="fetchData"
    />

    <!-- 文件上传模态框 -->
    <new-agreement
      v-if="isShowNewModal"
      :visible.sync="isShowNewModal"
      @completed="fetchData"
    />
  </div>
</template>

<script>
import Pagination from '@/components/Pagination/index'
import SearchAgreement from '@/views/agreements/platform_service/Search'
import { hasPermission } from '@/utils/permission'
import { deletePlatformServiceAgreement, findPlatformServiceAgreements, UpdateAgreementToUsing } from '@/api/agreement'

export default {
  name: 'RegisteredAgreementList',
  components: {
    SearchAgreement,
    Pagination,
    NewAgreement: () => import('@/views/agreements/platform_service/New')
  },
  data() {
    return {
      data: [],
      loading: true,
      isShowNewModal: false, // 是否显示上传文件弹窗
      pagination: {
        total_count: 0
      },
      query: {},
      agreementType: 'platform_service'
    }
  },
  created() {
    this.fetchData()
  },
  computed: {
    columns() {
      return [
        {
          title: '上传时间',
          dataIndex: 'created_at'
        },
        {
          title: '协议主体',
          dataIndex: 'contracting_party'
        },
        {
          title: '协议名称',
          dataIndex: 'name',
          scopedSlots: { customRender: 'name' }
        },
        {
          title: '版本号',
          dataIndex: 'version'
        },
        {
          title: '文件大小',
          dataIndex: 'attachment_size'
        },
        {
          title: '上传者',
          dataIndex: 'upload_user'
        },
        {
          title: '使用客户数',
          dataIndex: 'agent_count'
        },
        {
          title: '状态',
          dataIndex: 'is_using',
          scopedSlots: { customRender: 'is_using' }
        },
        {
          title: '说明',
          dataIndex: 'description',
          ellipsis: true,
          width: 100
        },
        {
          title: '操作',
          dataIndex: 'actions',
          fixed: 'right',
          scopedSlots: { customRender: 'actions' }
        }
      ]
    }
  },
  methods: {
    isShowMenu(menu) {
      return hasPermission(menu)
    },

    // 显示上传文件弹窗
    showNewModal() {
      this.isShowNewModal = true
    },

    submitSearch(search) {
      // 赋值搜索条件，保留 per_page
      this.query = Object.assign({},
        this.$options.data().query,
        { per_page: this.query.per_page },
        search)
      this.fetchData()
    },

    fetchData() {
      this.loading = true
      findPlatformServiceAgreements(Object.assign({}, this.query)).then((res) => {
        this.data = res.data
        this.pagination = res.pagination
        this.loading = false
      })
    },

    // 更新协议状态
    updateAgreementToUsing(agreementId, contracting_party_id) {
      UpdateAgreementToUsing(agreementId, {
        agreement_type: this.agreementType,
        is_using: true,
        contracting_party_id: contracting_party_id
      }).then(this.fetchData)
    },

    // 删除协议
    deletePlatformServiceAgreement(agreementId) {
      const thisAgreement = this
      this.$confirm({
        title: '确定删除此文件吗?',
        content: '',
        okText: '确定',
        okType: 'danger',
        cancelText: '取消',
        onOk() {
          deletePlatformServiceAgreement(agreementId).then(() => {
            thisAgreement.fetchData()
          })
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.delete {
  color: red;
}
</style>

